import { useState } from "react";
import { useEffect } from "react";
import styles from "./index.module.scss";

const WowAnimation = () => {
  const [bgPos, setBgPos] = useState(0);
  
  useEffect(() => {
    const interval = setInterval(() => {
      setBgPos((prev) => prev - 0.8);
    }, 10);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div style={{ "backgroundPositionX": bgPos }} className={styles["wow"]} />
  );
};

export default WowAnimation;
