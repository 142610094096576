import { Autoplay, Navigation } from "swiper";
import 'swiper/swiper.min.css'
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { sliders } from "../../constants";
import PrevBtn from "./btns";
import styles from "./index.module.scss";


const Slider = () => {
  return (
    <Swiper
      autoplay={{ delay: 3000 }}
      className={styles.slider}
      slidesPerView={1}
      modules={[Navigation, Autoplay]}
      centeredSlides
      initialSlide={3}
      loop={true}
      breakpoints={{
        567: {
          slidesPerView: 3,
        },
      }}
    >
      <PrevBtn />
      {sliders.map((item) => (
        <SwiperSlide key={item.id}>
          <div className={styles["slider__img"]}>
            <img src={item.img} alt={item.name} />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Slider;
