import styles from "./index.module.scss";

const FaqCard = ({ name, desc }) => {
  return (
    <div className={styles["card"]}>
      <h3 className={styles["card__name"]}>{name}</h3>
      <p dangerouslySetInnerHTML={{__html: desc}} className={styles["card__position"]} />
    </div>
  );
};

export default FaqCard;
