import { BrowserRouter as Router } from 'react-router-dom'
import { Route, Routes } from 'react-router'
import './styles/index.scss'
import Home from "./pages/Home"
import { PATHS } from './constants';
import Privacy from './pages/Privacy';
import Merch from './pages/Merch';


function App() {
  return (
    <Router>
      <Routes>
        <Route path={PATHS.home} element={<Home />} />
        <Route path={PATHS.privacy} element={<Privacy />} />
        <Route path={PATHS.merch} element={<Merch />} />
      </Routes>
    </Router>
  );
}

export default App;
