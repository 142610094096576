import React, { createContext, useState } from "react";

import { ethers } from "ethers";
import { toast } from "react-hot-toast";
import { TRAIN_STATUS } from '../constants';
import abi from "../output/abi.json";
import { FormatError } from "../tools";

const CONTRACT_ADDRESS = "0x631D6388A9609b542D0356411cb8b7a85173913d";
const CORRECT_NET_ID = 1;

export const DAppContext = createContext(null);

export const DAppProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [transactionHash, setTransactionHash] = useState("");
  const [loading, setLoading] = useState(false);
  const [contractDetails, setContractDetails] = useState(null);
  const [islaunched, setIsLaunched] = useState(false);

  const connectToContract = async (provider, signer) => {
    try {
      const instance = new ethers.Contract(CONTRACT_ADDRESS, abi, provider);
      const contractWithSigner = instance.connect(signer);
      let details = {};

      const {
        isActive,
        mintPrice,
        MAX_SUPPLY,
        name,
        totalSupply = () => {},
        maximumAllowedTokensPerPurchase,
      } = contractWithSigner;

      const collectionName = await name();
      const isPublicSaleActive = await isActive();
      const totalSupplyNFT = await MAX_SUPPLY();
      const publicETHPrice = ethers.utils.formatEther(`${await mintPrice()}`);
      const maximumAllowed = await maximumAllowedTokensPerPurchase()

      const alreadyMinted = Number(await totalSupply());

      details = {
        ...details,
        price: publicETHPrice,
        collectionName,
        isPublicSaleActive,
        totalSupplyNFT,
        alreadyMinted,
        methods: contractWithSigner,
        maximumAllowed: maximumAllowed.toNumber()

      };

      setContractDetails(details);
      setLoading(false)
    } catch (error) {
      console.log(error, "Error");
    }
  };

  const connectBrowserWallet = async () => {
    try {
      setLoading(true)
      const web3Provider = new ethers.providers.Web3Provider(window.ethereum);
      await web3Provider.send("eth_requestAccounts", []);
      const signer = web3Provider.getSigner();
      const accounts = await signer.getAddress();
      const balance =  await web3Provider.getBalance(accounts);
      const { chainId } = await web3Provider.getNetwork();

      const gas = await web3Provider.getGasPrice();

      if (parseInt(chainId) !== CORRECT_NET_ID)
        return toast.error("Please change to MainNet");

      setUserData({
        account: accounts,
        chainId: Number(chainId),
        accountBalance: Number(ethers.utils.formatEther(balance)),
        gasEstimate: Number(ethers.utils.formatEther(gas.toNumber())),
      });
      await connectToContract(web3Provider, signer, accounts);
      return true;
    } catch (error) {
      toast.error(`Please install Metamsk`);
      console.log(error, "Error");
      return false;
    } finally {
      setLoading(false)
    }
  };

  const checkBalance = async (setStatus) => {
      try {
        const account = userData.account;
        if (!contractDetails) return toast.error(`No instance`);
        if (!account) return toast.error(`No account selected. Try reauthenticating`);

        const {   
          balanceOf 
        } = contractDetails.methods;
        
        const userBalance = await balanceOf(account);

        return userBalance >= 1 ? setStatus(TRAIN_STATUS.success)  : setStatus(TRAIN_STATUS.error);
      } catch (error) {
        toast.error(error.message);
      }
  }

  const mint = async (count = 1) => {
    setLoading(true)
    try {
      const { account, accountBalance, gasEstimate } = userData;
      if (!contractDetails) return toast.error(`No instance`);
      if (!account)
        return toast.error(`No account selected. Try reauthenticating`);
      if (!count) return toast.error(`No token count provided.`);

      const {   
          isActive,
          mint,
          mintPrice,
          balanceOf 
        } = contractDetails.methods;

      const isPublicSaleActive = await isActive();
      const pusbliSaleCost = await mintPrice();
      const userBalance = await balanceOf(account);
      const price = pusbliSaleCost;
      const priceNumeric = Number(ethers.utils.formatEther(price))
    
      let cost = 0;
      const options = { value: cost };
      if (!isPublicSaleActive) return toast.error(`Sale has not started yet`);

      if (gasEstimate > accountBalance) return toast.error(`Insufficient funds`)

      cost = window.BigInt(`${count * price}`);
      options.value = cost;

      const { hash } = await mint(count, options);
      setTransactionHash(hash);
      setContractDetails({
        ...contractDetails,
        alreadyMinted: contractDetails.alreadyMinted + count
      });
    } catch (error) {
      console.log(error);
      toast.error(FormatError(error.code));
    } finally {
      setLoading(false)
    }
  };

  const resetTransactionHash = () => {
    setTransactionHash("");
  };


  return (
    <DAppContext.Provider
      value={{
        connectBrowserWallet,
        mint,
        checkBalance,
        loading,
        transactionHash,
        resetTransactionHash,
        contractDetails,
        userData,
        setIsLaunched,
        islaunched,
      }}
    >
      {children}
    </DAppContext.Provider>
  );
};
