import {
  slider1,
  slider2,
  slider3,
  slider4,
  slider5,
  slider6,
  team1,
  team2,
  team3,
  team4,
} from "./assets";

export const PATHS = {
  home: "/",
  roadmap: "roadmap",
  privacy: "privacy",
  merch: "merch",
};

export const TRAIN_STATUS = {
  success: 'success',
  error: 'error',
}

export const EMAILJS = {
  publicKey: "ybvsGfeR94l9c02So",
  serviceId: "service_36rffz3",
  templateId: "template_5i1c9kg",
};

export const menu = ["vision", "story", "team", "faq"];

export const sliders = [
  { id: 1, name: "slider", img: slider1 },
  { id: 2, name: "slider", img: slider2 },
  { id: 3, name: "slider", img: slider3 },
  { id: 4, name: "slider", img: slider4 },
  { id: 5, name: "slider", img: slider5 },
  { id: 6, name: "slider", img: slider6 },
];

export const faq = [
  {
    id: 1,
    name: "What is the Guilty Bunnies NFT supply?",
    desc: "6,969 NFTs in the first collection, but as you know Bunnies love to breed 😉 Also we are evolution project with diatomic metadata, which means your bunnies  is going to changing over time, not worries, your bunnies will always be  more beautiful, hotter, sexier",
  },
  {
    id: 2,
    name: "Where I can buy guilty bunnies?",
    desc: "Our collection was sold out in less than 5 minutes. But now you can buy your guilty bunny on secondary market, like Opens or Blur",
  },
  {
    id: 3,
    name: "What is  the Guilty elixir and why I need it?",
    desc: `<p>Guilty elixir was created to give an option to breed your bunnies, you need 1 elixir per 2 bunnies to breed them, after using your elixir  you will get a third bunny based on traits of your existing  bunnies.</p>
    <p>All elixirs was distributed between holders, and remaining of the supply of guilty elixirs has been burned ( around 80%)
    </p>
    </br>
    <p>The good news  you still has an option to pick it up on secondary market.</p>
    `,
  },
  { 
    id: 4, name: "What is Legendaries Guilty Vamps?", 
    desc: `
    <p>It is a legendary  collection with a supply of 168 bunnies, and this collection has  own mission and purpose.</p>
    <p>Half of the collection was distributed between top holders, second half is reserved for charity. In guilty bunnies we are big karma believers, we will be hosting charity auctions and will be donating all profits to kids who terminally  ill.  By doing right thing and giving we will earn way more all together.</p>`
   },
  {
    id: 5,
    name: "Do I own IP rights off the art work?",
    desc: "Yes all IP rights on the artwork is belong to you, but guilty bunnies , guilty lingerie and logo belongs to Guilty Bunnies NFT.",
  },
  {
    id: 6,
    name: "What are the Guilty Bunnies NFT Utilities?",
    desc: `<p>We own a guilty lingerie line,  this  will be always limited edition and highest quality on the market. We also  going to spoil our holders with a new collection every 4 months.  15% of profit from lingerie sales will go to our liquidity pool.</p>
    </br>
    <p>We have a staking platform, so each holder will be able to  to earn tokens which will be baked by our liquidity pool.</p>
    </br>
    <p>Guilty Bunnies is a metaverse ready,  and   we have lot of developments going on in this direction, be patient and you won’t be disappointed with the results.</p>
    </br>
    <p>In Guilty bunnies  our target is to provide best quality product to our holders, that’s why we are always looking for innovations and exciting partnerships, it means that  we are always going to be adding new utilities to our project.</p>
    `,
  },
];

export const team = [
  {
    id: 1,
    img: team1,
    name: "Guilty Sabrina",
    position: "Position",
    social: [
      {
        link: "#",
        icon: (
          <svg
            width="27"
            height="21"
            viewBox="0 0 27 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M26.0334 2.42308C25.0642 2.90769 24.0949 3.06923 22.9642 3.23077C24.0949 2.58462 24.9026 1.61538 25.2257 0.323077C24.2565 0.96923 23.1257 1.29231 21.8334 1.61538C20.8642 0.646154 19.4103 0 17.9565 0C14.5642 0 11.9796 3.23077 12.7873 6.46154C8.42572 6.3 4.54879 4.2 1.80264 0.969231C0.348794 3.39231 1.15649 6.46154 3.41803 8.07692C2.61033 8.07692 1.80264 7.75385 0.994948 7.43077C0.994948 9.85385 2.77187 12.1154 5.19495 12.7615C4.38726 12.9231 3.57956 13.0846 2.77187 12.9231C3.41803 15.0231 5.35649 16.6385 7.77956 16.6385C5.8411 18.0923 2.93341 18.9 0.187256 18.5769C2.61033 20.0308 5.35649 21 8.26418 21C18.118 21 23.6103 12.7615 23.2873 5.16923C24.418 4.52308 25.3873 3.55385 26.0334 2.42308Z"
              fill="currentColor"
            />
          </svg>
        ),
      },
    ],
  },
  {
    id: 2,
    img: team3,
    name: "Guilty Angel",
    position: "Position",
    social: [
      {
        link: "#",
        icon: (
          <svg
            width="27"
            height="21"
            viewBox="0 0 27 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M26.0334 2.42308C25.0642 2.90769 24.0949 3.06923 22.9642 3.23077C24.0949 2.58462 24.9026 1.61538 25.2257 0.323077C24.2565 0.96923 23.1257 1.29231 21.8334 1.61538C20.8642 0.646154 19.4103 0 17.9565 0C14.5642 0 11.9796 3.23077 12.7873 6.46154C8.42572 6.3 4.54879 4.2 1.80264 0.969231C0.348794 3.39231 1.15649 6.46154 3.41803 8.07692C2.61033 8.07692 1.80264 7.75385 0.994948 7.43077C0.994948 9.85385 2.77187 12.1154 5.19495 12.7615C4.38726 12.9231 3.57956 13.0846 2.77187 12.9231C3.41803 15.0231 5.35649 16.6385 7.77956 16.6385C5.8411 18.0923 2.93341 18.9 0.187256 18.5769C2.61033 20.0308 5.35649 21 8.26418 21C18.118 21 23.6103 12.7615 23.2873 5.16923C24.418 4.52308 25.3873 3.55385 26.0334 2.42308Z"
              fill="currentColor"
            />
          </svg>
        ),
      },
    ],
  },
  {
    id: 3,
    img: team2,
    name: "Guilty Bestia",
    position: "Position",
    social: [
      {
        link: "#",
        icon: (
          <svg
            width="27"
            height="21"
            viewBox="0 0 27 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M26.0334 2.42308C25.0642 2.90769 24.0949 3.06923 22.9642 3.23077C24.0949 2.58462 24.9026 1.61538 25.2257 0.323077C24.2565 0.96923 23.1257 1.29231 21.8334 1.61538C20.8642 0.646154 19.4103 0 17.9565 0C14.5642 0 11.9796 3.23077 12.7873 6.46154C8.42572 6.3 4.54879 4.2 1.80264 0.969231C0.348794 3.39231 1.15649 6.46154 3.41803 8.07692C2.61033 8.07692 1.80264 7.75385 0.994948 7.43077C0.994948 9.85385 2.77187 12.1154 5.19495 12.7615C4.38726 12.9231 3.57956 13.0846 2.77187 12.9231C3.41803 15.0231 5.35649 16.6385 7.77956 16.6385C5.8411 18.0923 2.93341 18.9 0.187256 18.5769C2.61033 20.0308 5.35649 21 8.26418 21C18.118 21 23.6103 12.7615 23.2873 5.16923C24.418 4.52308 25.3873 3.55385 26.0334 2.42308Z"
              fill="currentColor"
            />
          </svg>
        ),
      },
    ],
  },
  {
    id: 4,
    img: team4,
    name: "Guilty Kis Kisыч",
    position: "Position",
    social: [
      {
        link: "#",
        icon: (
          <svg
            width="27"
            height="21"
            viewBox="0 0 27 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M26.0334 2.42308C25.0642 2.90769 24.0949 3.06923 22.9642 3.23077C24.0949 2.58462 24.9026 1.61538 25.2257 0.323077C24.2565 0.96923 23.1257 1.29231 21.8334 1.61538C20.8642 0.646154 19.4103 0 17.9565 0C14.5642 0 11.9796 3.23077 12.7873 6.46154C8.42572 6.3 4.54879 4.2 1.80264 0.969231C0.348794 3.39231 1.15649 6.46154 3.41803 8.07692C2.61033 8.07692 1.80264 7.75385 0.994948 7.43077C0.994948 9.85385 2.77187 12.1154 5.19495 12.7615C4.38726 12.9231 3.57956 13.0846 2.77187 12.9231C3.41803 15.0231 5.35649 16.6385 7.77956 16.6385C5.8411 18.0923 2.93341 18.9 0.187256 18.5769C2.61033 20.0308 5.35649 21 8.26418 21C18.118 21 23.6103 12.7615 23.2873 5.16923C24.418 4.52308 25.3873 3.55385 26.0334 2.42308Z"
              fill="currentColor"
            />
          </svg>
        ),
      },
    ],
  },
];
