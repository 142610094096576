import styles from "./index.module.scss";

const TeamCard = ({ name, position, img, social }) => {
  return (
    <div className={styles["card"]}>
      <img className={styles["card__img"]} src={img} alt={name} />
      <h3 className={styles['card__name']}>{name}</h3>
      {/* <p className={styles['card__position']}>{position}</p> */}
      {/* {social.map((item, idx) => (
        <a key={idx} href={item.link}>
          <div className={styles.card__icon}>{item.icon}</div>
        </a>
      ))} */}
    </div>
  );
};

export default TeamCard;
