import { useState, useContext, useEffect } from "react";
import { DAppContext } from "../../context";
import { Link } from "react-router-dom";
import { PATHS, TRAIN_STATUS } from "../../constants";
import Loader from "../Loader";
import styles from "./index.module.scss";
import { getFirstAndLastSymbols } from "../../tools";

const Train = () => {
  const { userData, connectBrowserWallet, loading, checkBalance, contractDetails } = useContext(DAppContext);
  const [status, setStatus] = useState();

  useEffect(() => {
      if(contractDetails && userData?.account) {
        checkBalance(setStatus);  
      }
  }, [userData?.account, checkBalance, contractDetails])

  return (
    <section id="train" className={styles.train}>
      <div className={"container"}>
        <h2 className="h1">
          <strong className="text-pink">Your guilty fantasy awaits</strong>
        </h2>
        <div className={styles["train__wrap"]}>
          <div className={styles["train__address"]}>
            {getFirstAndLastSymbols(userData?.account) ?? "Wallet Address"}
          </div>
          {loading && <Loader />}
          {!loading && status !== TRAIN_STATUS.success && (
            <button onClick={connectBrowserWallet} className="btn">
              Connect Wallet
            </button>
          )}
          {!loading && status === TRAIN_STATUS.success && (
            <Link className="btn" to={PATHS.merch}>
              BUY MERCH
            </Link>
          )}
        </div>
        {status && (
          <p className={styles["train__status"]}>
            {status === TRAIN_STATUS.success
              ? "Success"
              : "Incorrect Wallet Address"}
          </p>
        )}
      </div>
    </section>
  );
};

export default Train;
