import cn from "classnames";
import { useEffect, useState, useContext } from "react";
import { Discord, Instagram, logo, opensea, Twitter } from "../../assets/index";
import { menu } from "../../constants";
import Modal from "../Modal";
import styles from "./index.module.scss";


const Header = () => {

  const [isOpen, setIsOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const handleSticky = () => {
    if (window.pageYOffset > 100) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    window.pageYOffset > 100 && setIsSticky(true);
    window.addEventListener("scroll", handleSticky);
    return () => {
      window.removeEventListener("scroll", handleSticky);
    };
  }, []);

  const handleToogleMenu = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className="container">
      <header
        className={cn(styles.header, {
          [styles.header_fixed]: isSticky || isOpen,
        })}
      >
        <div className={styles.header__wrap}>
          <a href="/#intro">
            <img className={styles.header__logo} src={logo} alt="Logo" />
          </a>

          <nav>
            <ul
              className={cn(styles.menu, {
                [styles.menu__open]: isOpen,
              })}
            >
              {menu.map((name, idx) => (
                <li key={idx}>
                  <a
                    onClick={() => setIsOpen(false)}
                    className={styles.menu__link}
                    href={`/#${name}`}
                  >
                    {name}
                  </a>{" "}
                </li>
              ))}

              <li>
                <div className='shop'>
                  <a href="https://guiltybunnies.shop/"> GUILTY SHOP</a>
                </div>
              </li>
              {/* <li>
                <div>
                  <button onClick={connectBrowserWallet} className="btn">
                    Connect Wallet
                  </button>
                </div>
              </li> */}
              {/* <li>
                <div className={styles["header__btn-get_bg"]}>
                  <a
                    onClick={() => setIsOpen(false)}
                    className={styles["header__btn-get"]}
                    href={`/#mint`}
                  >
                    Mint Now
                  </a>
                </div>
              </li> */}
              {/* <li>
                <div className={styles["header__btn-get_bg"]}>
                  <button
                    className={styles["header__btn-get"]}
                    onClick={() => setModalIsOpen(true)}
                  >
                    Get Guilty Now
                  </button>
                </div>
              </li> */}
            </ul>
          </nav>
          <div className={styles.social}>
            <a
              rel="noreferrer"
              className={styles.social__icon}
              target="_blank"
              href="https://discord.gg/7nEWGqqh"
            >
              <Discord />
              <strong>Discord</strong>
            </a>
            <a
              rel="noreferrer"
              className={styles.social__icon}
              target="_blank"
              href="https://twitter.com/GuiltyBunnies"
            >
              <Twitter />
              <strong>Twitter</strong>
            </a>
            <a
              rel="noreferrer"
              className={styles.social__icon}
              target="_blank"
              href="https://www.instagram.com/guiltybunnies.eth/"
            >
              <Instagram />
              <strong>Instagram</strong>
            </a>
            <a
              rel="noreferrer"
              className={styles.social__icon}
              target="_blank"
              href="https://opensea.io/collection/guiltybunniesofficial"
            >
              <img src={opensea} alt="Opensea" />
              <strong>Opensea</strong>
            </a>
          </div>
          <button onClick={handleToogleMenu} className={styles.burger__btn}>
            <div
              className={cn(styles.burger__icon, {
                [styles.burger__icon_open]: isOpen,
              })}
            />
          </button>
        </div>
      </header>
      <Modal isOpen={modalIsOpen} closeModal={closeModal} />
    </div>
  );
};

export default Header;
