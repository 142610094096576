import { useSwiper } from "swiper/react/swiper-react";
import { Triangle } from "../../assets";
import styles from "./index.module.scss";

const PrevBtn = () => {
  const swiper = useSwiper();

  return (
    <div className={styles.btns}>
      <button
        disabled={!swiper.allowSlidePrev}
        onClick={() => swiper.slidePrev()}
      >
        <Triangle className={styles["btns__icon_left"]} />
      </button>
      <button
        disabled={!swiper.allowSlideNext}
        onClick={() => swiper.slideNext()}
      >
        <Triangle className={styles["btns__icon_right"]} />
      </button>
    </div>
  );
};

export default PrevBtn;
