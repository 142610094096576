export const getFirstAndLastSymbols = (string, fisrtIdx = 4, LastIdx = -4) => {
  if (string) {
    return `${string.slice(0, fisrtIdx)}...${string.slice(LastIdx)}`;
  }
};

export function FormatError(string) {
  const removeUnderscore = string.split("_").join(" ");
  return (
    removeUnderscore.charAt(0).toUpperCase() +
    removeUnderscore.slice(1).toLowerCase()
  );
}
