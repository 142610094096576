import { useEffect, useState } from "react";
import emailjs from "@emailjs/browser";
import { useFormik } from "formik";
import styles from "./index.module.scss";
import { EMAILJS } from "../../constants";
import { schema } from "../../schema";
import Loader from "../Loader";

const Form = () => {
  useEffect(() => {
    emailjs.init(EMAILJS.publicKey);
  }, []);

  const [emailStatus, setEmailStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
  };

  const handleSubmit = async (data, { resetForm }) => {
    setLoading(true);
    try {
      await emailjs.send(EMAILJS.serviceId, EMAILJS.templateId, data);
      resetForm({});
      setEmailStatus(true);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  return (
    <form className={styles.form}>
      <div className={styles.form__group}>
        <div className={styles.form__group}>
          <input
            onChange={formik.handleChange}
            name="first_name"
            className={styles.form__input}
            type="text"
            placeholder="First Name"
            value={formik.values.first_name}
          />
        </div>
        <div className={styles.form__group}>
          <input
            onChange={formik.handleChange}
            name="last_name"
            className={styles.form__input}
            type="text"
            placeholder="Last Name"
            value={formik.values.last_name}
          />
        </div>
        <div className={styles.form__group}>
          <input
            onChange={formik.handleChange}
            name="email"
            className={styles.form__input}
            type="text"
            placeholder="Email"
            value={formik.values.email}
          />
          <p className={styles["form__error-text"]}>{formik.errors.email}</p>
        </div>
      </div>
      {emailStatus && (
        <div className={styles["form__group"]}>
          <p className={styles["form__success-text"]}>
            We have received your message and would like to thank you for
            writing to us.
          </p>
        </div>
      )}
      <div className={styles.form__group}>
        <button
          onClick={formik.handleSubmit}
          className={styles.form__btn}
          type="button"
        >
          {loading ? <Loader /> : "Get Guilty Now"}
        </button>
      </div>
    </form>
  );
};

export default Form;
