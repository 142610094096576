import Header from "../../components/Header";
import styles from "./home.module.scss";
import {
  introVideo,
  joinBgVideo,
  promo,
  storyBgVideo,
  Twitter,
  visionBg,
  visionBgVideo,
  wowGif,
} from "../../assets/index";
import Slider from "../../components/Slider";
import TeamCard from "../../components/TeamCard";
import { faq, team } from "../../constants";
import FaqCard from "../../components/FaqCard";
import Footer from "../../components/Footer";
import WowAnimation from "../../components/WowAnimation";
import Mint from "../../components/Mint";
import { useContext, useState } from "react";
import Modal from "../../components/Modal";
import Train from "../../components/Train";
import Timer from "../../components/Timer/Index";
import { DAppContext } from "../../context";
import { Toaster } from "react-hot-toast";
import { isBrowser } from "react-device-detect";

const Home = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const { islaunched } = useContext(DAppContext);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      <section id="intro" className={styles.intro}>
        {isBrowser && (
          <video className={styles.intro__video} autoPlay muted loop>
            <source src={introVideo} type="video/mp4" />
          </video>
        )}
        <Header />
        <div className={"container"}>
          <div className={styles.intro__wrap}>
            <h1 className={styles.intro__title}>
              The Hottest NFT
              <br />
              <span className="text-blue">on ETH</span>
            </h1>
            <div className="sold-out">
              <div className='sold-out-text'>Sold Out</div>
            </div>
          </div>
        </div>
      </section>
      <Train />
      {/* {!islaunched && <Timer />} */}
      {/* <Mint /> */}
      <section id="video" className={styles.video}>
        {isBrowser && (
          <video className={styles.video__player_bg} autoPlay muted loop>
            <source src={joinBgVideo} type="video/mp4" />
          </video>
        )}
        <div className={"container"}>
          <div className="relative">
            <h2 className={styles.video__title}>
              Everyone has a Secret Life,
              <br /> and this Collection will prove it.
            </h2>
            <div>
              {isBrowser && (
                <video
                  className={styles.video__player}
                  controls
                  autoPlay
                  muted
                  loop
                >
                  <source src={promo} type="video/mp4" />
                </video>
              )}
            </div>
            <a
              href="https://twitter.com/GuiltyBunnies"
              target="_blank"
              className="btn"
              rel="noreferrer"
            >
              <Twitter /> <strong>join us</strong>
            </a>
          </div>
        </div>
      </section>
      <section id="vision" className={styles.guilty}>
        <img className={styles["guilty__bg"]} src={visionBg} alt="Bunny" />
        {isBrowser && (
          <video className={styles.guilty__video} autoPlay muted loop>
            <source src={visionBgVideo} type="video/mp4" />
          </video>
        )}
        <div className="container relative">
          <div className={styles["guilty__wrap"]}>
            <h2 className={`${styles["guilty__title"]} h1`}>
              GUILTY
              <br />
              <strong className="text-pink">VISION</strong>
            </h2>
            <div className={styles["guilty__text-block"]}>
              <p>
              Welcome to world of Guilty Bunnies where pleasure awaits you…First NFT project with actual IRL utility
              </p>
              <p>
              A luxury lingerie collection featuring{" "}
                <strong>169 unique 3D traits. </strong>
                Each Guilty Bunny is completely unique and represents each of our unique secrets.
              </p>
              <p>
              Moreover, traits seeing on your bunnies its an actual luxury lingerie which is available for guilty bunny holders.
              </p>
              <p>
              Our lingerie is limited edition, and made in France, which means you you will get  best quality product available on the market, for amazing price.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="story" className={styles.story}>
        <div className="container">
          <h2 className="h1">
            GUILTY <strong className="text-pink">STORY</strong>
          </h2>
          <h3 className={styles.story__subtitle}>YES MASTER</h3>
          <div className={styles.story__text}>
            <p>
            Guilty Bunnies are the heroes of Guilty Studio’s Universe, a bunny-powered 3D world of supermodels turned cute furry superstars. These bunnies are guilty of the most deadly sin and their uncontrollable desire for the human form has transformed them into sexy 3D characters.
            </p>
            <p>You might know a Guilty Bunny or even be one yourself.</p>
            <p>
            Each collection of Guilty Bunnies gets more and more obsessed with their desire to become the most beautiful bunnies of the entire Internet. They cannot stop until their mission is complete.
            </p>
          </div>
        </div>
        <div className={styles["story__wrap"]}>
          <Slider />
        </div>
        <div>
          {isBrowser && (
            <video className={styles.story__video} autoPlay muted loop>
              <source src={storyBgVideo} type="video/mp4" />
            </video>
          )}
        </div>
      </section>
      <section id="team" className={styles.team}>
        <div className="container">
          <h2 className="h1">
          OUR BRAND<strong className="text-pink"> AMBASSADORS</strong>
          </h2>
          <div className={styles["team__desc"]}>
            <p>
              Each Guilty Bunnies NFT holder becomes a part of the Guilty
              Bunnies Team. You might have seen a Guilty Bunny at your latest
              cocktail party or wine mixer, you might have seen a Guilty Bunny
              at the top of your social media feed, or you might already be a
              part of the Guilty Bunnies team yourself.
            </p>
            <p className="text-pink">
              The core team consists of four sexy bunnies, Guilty Sabrina,{" "}
              Guilty Angel, Guilty Bestia, and Guilty Kis Kisыч.
            </p>
          </div>
          <div className={styles.team__staff}>
            {team.map((item) => (
              <TeamCard key={item.id} {...item} />
            ))}
          </div>
        </div>
      </section>
      <section id="wow" className={styles.wow}>
        <div className="container">
          <div className={styles["wow__wrap"]}>
            <div>
              <img src={wowGif} alt="Wow" />
            </div>
            <div>
              <div className={styles["wow__text"]}>
                <p>Unlock exclusive rewards and hot surprises.</p>
                <span className="text-pink">
                  <p>
                  Join our guilty family and explore our guilty world….
                  </p>
                  <p>
                  We have lingerie, staking, private events  and always have something special for our holders.
                  </p>
                </span>
              </div>
              <button className="btn">Wetlist FULL</button>
            </div>
          </div>
        </div>
        <div className={styles["wow-animation"]}>
          <WowAnimation />
        </div>
      </section>
      <section id="faq" className={styles.faq}>
        <div className="container">
          <h2 className={`h1 ${styles["faq__title"]}`}>FAQ</h2>
          <div>
            {faq.map((item) => (
              <FaqCard key={item.id} {...item} />
            ))}
          </div>
        </div>
      </section>
      <Modal isOpen={modalIsOpen} closeModal={closeModal} />
      <Footer />
      <Toaster
        containerStyle={{
          zIndex: 999999,
          top: "120px",
        }}
        position="top-center"
      />
    </>
  );
};

export default Home;
