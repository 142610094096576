import { useContext } from "react";
import { DAppContext } from "../../context";
import { comingSoon } from "../../assets"
import styles from "./merch.module.scss"

const Merch = () => {
    const { userData } = useContext(DAppContext);

    if(!userData) return null;

    return(
        <img className={styles["merch__img"]} src={comingSoon} alt="Coming Soon" />
    )
}

export default Merch;