import { isBrowser } from "react-device-detect";
import { Link } from "react-router-dom";
import {
  Discord,
  fLogo,
  footerBgVideo,
  Instagram,
  opensea,
  Twitter,
} from "../../assets";
import { menu, PATHS } from "../../constants";
import styles from "./index.module.scss";

const Footer = () => {
  const date = new Date();
  return (
    <footer className={styles.footer}>
      {isBrowser && (
        <video className={styles.footer__video} autoPlay muted loop>
          <source src={footerBgVideo} type="video/mp4" />
        </video>
      )}
      <div className="container">
        <div className={styles.footer__wrap}>
          <img className={styles.footer__logo} src={fLogo} alt="Logo" />
          <h2 className={styles.footer__title}>Join the Community</h2>
          <div className={styles.social}>
            <a
              className={styles.social__icon}
              target="_blank"
              href="https://twitter.com/GuiltyBunnies"
            >
              <Twitter />
            </a>
            <a
              className={styles.social__icon}
              target="_blank"
              href="https://discord.gg/7nEWGqqh"
            >
              <Discord />
            </a>
            <a
              className={styles.social__icon}
              target="_blank"
              href="https://www.instagram.com/guiltybunnies.eth/"
            >
              <Instagram />
            </a>
            <a
              rel="noreferrer"
              className={styles.social__icon}
              target="_blank"
              href="https://opensea.io/collection/guiltybunniesofficial"
            >
              <img src={opensea} alt="Opensea" />
            </a>
          </div>
          <nav>
            <ul className={styles.menu}>
              {menu.map((item, idx) => (
                <li className={styles.menu__item} key={idx}>
                  <a className={styles.menu__link} href={`#${item}`}>
                    {item}
                  </a>
                </li>
              ))}
              <li className={styles.menu__item}>
                <Link className={styles.menu__link} to={PATHS.privacy}>
                  PRIVACY POLICY
                </Link>
              </li>
            </ul>
          </nav>
          <p>© {date.getFullYear()} Guilty Bunny. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
